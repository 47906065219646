<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { API, HOST } from '@/utils/http'
import { centroid, bbox } from '@turf/turf'
import { debounce } from 'lodash'

import { MapSource, MapLayer, MapImageMarker } from '@/components/map'
import { Spinner } from '@/components/ui/spinner'

const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
})

const router = useRouter()
const route = useRoute()
const store = useStore()

const isLoggedIn = computed(() => store.getters.getIsLoggedIn)
const beacons = computed(() => store.getters['geo/getBeacons'])
const params = computed(() => store.getters['search/getParams'])
const mapParamsKeys = ['zoom', 'center_lat', 'center_lng', 'south', 'north', 'east', 'west']

const features = computed(() => store.getters['geo/getFeatures'])

const filterParams = computed(() => {
  return params.value.filter((param) => !mapParamsKeys.includes(param.key))
})

const isLoadingData = ref(false)
const routeProjectId = computed(() => parseInt(route.params.project_id))
const featurePages = ['Feature', 'FeatureIndex', 'FeatureEdit']
const isFeaturePage = featurePages.includes(route.name)

const isCurrentProject = computed(() => {
  const routeProjectId = Number(route.params.project_id)
  return !isNaN(routeProjectId) && props.project.project_id === routeProjectId
})
const currentPageFeatureCode = computed(() => route.params.featureCode)
const featureCode = computed(() => props.project.paired_feature_code)
const feature = ref(null)
const featureCenter = computed(() => {
  if (!feature.value) return null
  return centroid(feature.value)
})


const getProjectBoundary = async (featureCode) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/feature/${featureCode}`
    })

    return response?.data || null
  } catch (e) {
    throw new Error(e)
  }
}

const onLayerClick = (feature) => {
  const bounds = bbox(feature)
  props.map.fitBounds(bounds, { padding: 50 })

  if (feature.properties.paired_project_id) {
    router.push({ name: 'Project', params: { project_id: props.project.project_id } })
  } else if (feature.properties.code) {
    router.push({ name: 'Feature', params: { project_id: props.project.project_id, featureCode: feature.properties.code } })
  }
}

const loadData = async () => {
  if (!routeProjectId.value || !isLoggedIn.value || !isCurrentProject.value) return

  isLoadingData.value = true

  const paramsMap = params.value.reduce((acc, param) => {
    acc[param.key] = param.value
    return acc
  }, {})

  const filterParamsMap = filterParams.value.reduce((acc, param) => {
    acc[param.key] = param.value
    return acc
  }, {})

  const payload = {
    project_id: routeProjectId.value,
    shared: filterParamsMap['shared'],
    has_resources: filterParamsMap['has_resources'],
    start_date: filterParamsMap['start_date'],
    end_date: filterParamsMap['end_date'],
    is_meta: false,

    // map params
    south: paramsMap['south'],
    north: paramsMap['north'],
    east: paramsMap['east'],
    west: paramsMap['west'],
  }

  await store.dispatch('geo/fetchFeatures', payload)

  isLoadingData.value = false
}

const updateBounds = async () => {
  props.map.setPadding({ top: 0, bottom: 0, left: 0, right: 0 })

  const bounds = props.map.getBounds()
  const center = props.map.getCenter()

  const options = {
    center_lat: center.lat,
    center_lng: center.lng,
    zoom: props.map.getZoom(),
    south: bounds.getSouth(),
    north: bounds.getNorth(),
    east: bounds.getEast(),
    west: bounds.getWest()
  }

  for (const [key, value] of Object.entries(options)) {
    store.commit('search/ADD_PARAM', {
      key: key,
      value: value
    })
  }

  localStorage.setItem('mapOptions', JSON.stringify({
    center: props.map.getCenter(),
    zoom: props.map.getZoom()
  }))

  await loadData()
}

const debouncedUpdateBounds = debounce(updateBounds, 100)

const setListeners = () => {
  props.map.on('dragend', debouncedUpdateBounds)
  props.map.on('zoomend', debouncedUpdateBounds)
}

const removeListeners = () => {
  props.map.off('dragend', debouncedUpdateBounds)
  props.map.off('zoomend', debouncedUpdateBounds)
}

onMounted(async () => {
  if (featureCode.value) {
    feature.value = await getProjectBoundary(featureCode.value)
  }

  if (!isCurrentProject.value) return
  await loadData()
  setListeners()
})

onUnmounted(() => {
  removeListeners()
})

watch(
  () => isCurrentProject.value, async (newValue, oldValue) => {
    if (newValue) {
      await loadData()
      setListeners()
    }

    if (!newValue) {
      removeListeners()
    }
  }
)

watch(
  () => filterParams.value,
  async (newValue, oldValue) => {
    if (!oldValue) return

    await loadData()
  },
  { deep: true, immediate: true }
)
</script>

<template>
<div v-if="isCurrentProject">
  <div v-if="isLoadingData" class="absolute z-50 top-14 bg-white rounded-full p-2 shadow-lg" style="left: 50%">
    <Spinner class="w-6 h-6" />
  </div>

  <div v-if="beacons.length" :key="beacons.length">
    <MapImageMarker
      v-for="feature in beacons"
      ping
      :label="feature.properties.member_display_name"
      :url="feature.properties.member_avatar_url"
      :feature="feature"
      :map="map"
      @click="(e) => onLayerClick(feature)"
    ></MapImageMarker>
  </div>

  <!-- Mixed geometry -->
  <!-- mapbox not rendering mixed geometry if clustering is enabled, so need to use two sources -->
  <MapSource
    :map="map"
    type="geojson"
    :id="'project-' + project.project_id + '-features-mixed'"
    :data="{
      type: 'FeatureCollection',
      features: features.features.filter(feature => feature.geometry.type !== 'Point' && feature.properties.code !== currentPageFeatureCode)
    }"
  >
    <MapLayer
      :map="map"
      :id="'project-' + project.project_id + '-report-fill'"
      :source="'project-' + project.project_id + '-features-mixed'"
      type="fill"
      :options="{
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.1
        },
        filter: ['all', ['!=', '$type', 'Point'], ['!=', '$type', 'LineString']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>
    <MapLayer
      :map="map"
      :id="'project-' + project.project_id + '-report-lines'"
      :source="'project-' + project.project_id + '-features-mixed'"
      type="line"
      :options="{
        paint: {
          'line-color': ['get', 'color'],
          'line-width': 2,
          'line-opacity': 1,
        },
        filter: ['!=', '$type', 'Point']
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>
  </MapSource>

  <!-- Points, Clusters and Images -->
  <MapSource
    :map="map"
    type="geojson"
    :id="'project-' + project.project_id + '-features-points'"
    :data="{
      type: 'FeatureCollection',
      features: features.features.filter(feature => feature.geometry.type === 'Point' && feature.properties.code !== currentPageFeatureCode)
    }"
    :options="{
      cluster: true,
      clusterMaxZoom: 13,
      // clusterRadius: 50,
      clusterMinPoints: 6
    }"
  >
    <MapLayer
      :map="map"
      :id="'project-' + project.project_id + '-clusters'"
      :source="'project-' + project.project_id + '-features-points'"
      type="circle"
      :options="{
        paint: {
          'circle-color': ['step', ['get', 'point_count'], '#2259ff', 100, '#2259ff', 750, '#2259ff'],
          'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        },
        filter: ['all', ['has', 'point_count']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>
    <MapLayer
      :map="map"
      :id="'project-' + project.project_id + '-cluster-count'"
      :source="'project-' + project.project_id + '-features-points'"
      type="symbol"
      :options="{
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-size': 18
        },
        paint: {
          'text-color': '#fff'
        },
        filter: ['all', ['has', 'point_count']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>

    <MapLayer
      :map="map"
      :id="'project-' + project.project_id + '-unclustered-point'"
      :source="'project-' + project.project_id + '-features-points'"
      type="circle"
      :options="{
        paint: {
          'circle-color': ['get', 'color'],
          'circle-radius': 8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        },
        filter: ['all', ['!has', 'point_count'], ['!has', 'thumbUrl']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>

    <!-- Image points -->
    <MapLayer
      :map="map"
      :id="'project-' + project.project_id + '-reports_points_with_resources-layer'"
      :source="'project-' + project.project_id + '-features-points'"
      type="symbol"
      :options="{
        layout: {
          'icon-image': ['get', 'thumbUrl'],
          'icon-size': 0.1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'center'
        },
        filter: ['all', ['!has', 'point_count'], ['has', 'thumbUrl']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>
  </MapSource>
</div>

<!-- Project Boundaries -->
<div v-if="feature && !isCurrentProject">
  <MapSource
    :map="map"
    type="geojson"
    :id="'project-' + project.project_id"
    :data="feature"
  >
    <MapLayer
      :map="map"
      :id="'project-' + project.project_id + '-fill'"
      :source="'project-' + project.project_id"
      type="fill"
      :options="{
        paint: {
          'fill-color': '#0039A3',
          'fill-opacity': 0.2
        },
        filter: ['==', '$type', 'Polygon']
      }"
      @click="(e) => onLayerClick(e.features[0])"
    />
    <MapLayer
      :map="map"
      :id="'project-' + project.project_id + '-lines'"
      :source="'project-' + project.project_id"
      type="line"
      :options="{
        paint: {
          'line-color': '#0039A3',
          'line-width': 2,
          'line-dasharray': [4, 2]
        },
        filter: ['any', ['==', '$type', 'Polygon']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    />

    <MapSource
      v-if="featureCenter"
      :map="map"
      type="geojson"
      :id="'project-' + project.project_id + '-center'"
      :data="featureCenter"
    >
      <MapLayer
        :map="map"
        :id="'project-' + project.project_id + '-center'"
        :source="'project-' + project.project_id + '-center'"
        type="symbol"
        :options="{
          minzoom: 0,
          maxzoom: 24,
          layout: {
            'text-field': project.project_name,
            'text-anchor': 'center',
            'text-justify': 'center',
            'text-variable-anchor': ['center'],
            'text-radial-offset': 0,
            'text-size': 16,
            'text-font': ['Open Sans Bold'],
            'text-allow-overlap': true
          },
          'paint': {
            'text-color': 'white',
            'text-halo-color': '#0039A3',
            'text-halo-width': 1
          }
        }"
        @click="(e) => onLayerClick(e.features[0])"
      />
    </MapSource>
  </MapSource>
</div>
</template>
