<!-- feature is a report, report is a feature 🗿 -->

<script setup>
import {computed, onBeforeMount, watch} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'

import PageMapData from './PageMapData.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()

const featureCode = computed(() => route.params.featureCode)
const map = computed(() => store.getters['geo/getMap'])
const feature = computed(() => store.getters['geo/getCurrentFeature'])
const isMapReady = computed(() => store.getters['geo/getIsMapReady'])

const fetchFeature = async (code) => {
  if (feature.value && feature.value?.properties?.code === code) {
    return
  }

  store.commit('geo/SET_CURRENT_FEATURE', {feature: null})

  await store.dispatch('geo/fetchFeature', code)

  if (!feature.value) {
    router.push({name: 'Dashboard'})
  }
}

onBeforeMount(async () => {
  await fetchFeature(featureCode.value)
})

watch(featureCode, async (code) => {
  await fetchFeature(code)
})
</script>

<template>
  <div :key="featureCode" class="bg-white">
    <router-view></router-view>
    <PageMapData v-if="feature && isMapReady" :key="feature.properties.code" :feature="feature" :map="map" />
  </div>
</template>
