<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Button } from '@/components/ui/button'
import { X } from 'lucide-vue-next'

// Router
const router = useRouter()
const route = useRoute()

// Drawer state and dimensions
const isBig = computed(() => route.meta.layout === 'float-big')
const isLargeViewport = computed(() => window.innerWidth > 1024)
const isShow = ref(true)
const drawerHeight = ref(30)
const isDragging = ref(false)
const headerHeight = ref(0)
let startY = 0

// Get the header height dynamically
onMounted(() => {
  const headerEl = document.querySelector('.drawer-header')
  if (headerEl) {
    headerHeight.value = headerEl.offsetHeight
  }
})

const startDrag = (e) => {
  isDragging.value = true
  startY = e.touches ? e.touches[0].clientY : e.clientY
}

const onDrag = (e) => {
  if (!isDragging.value) return
  const currentY = e.touches ? e.touches[0].clientY : e.clientY
  const diff = startY - currentY
  const newHeight = drawerHeight.value + (diff / window.innerHeight) * 100

  // Calculate minimum height based on header height
  const minHeight = (headerHeight.value / window.innerHeight) * 100
  if (newHeight <= 100 && newHeight >= minHeight) {
    drawerHeight.value = newHeight
  }
  startY = currentY
}

const endDrag = () => {
  if (drawerHeight.value >= 70) {
    drawerHeight.value = 100
  }
  isDragging.value = false
}

router.afterEach(() => {
  isShow.value = true
  drawerHeight.value = 30
})

</script>

<template>
    <div
      v-if="route.meta.layout !== 'hide' && isShow && isLargeViewport"
      class="flex-1 text-sm relative"
      :class="{'min-w-[700px] max-w-[700px]': isBig, 'min-w-[340px] max-w-[340px]': !isBig}"
    >
      <div class="p-1 flex items-center justify-between sticky top-0 bg-white shadow z-10">
        <div class="font-bold">
          {{ route.meta.title }}
        </div>
        <Button
          @click="isShow = false"
          variant="ghost"
          class="w-6 h-6 p-0 rounded-full"
        >
          <X class="w-5 h-5" />
        </Button>
      </div>
      <div class="flex-1 h-full overflow-y-auto">
        <router-view />
      </div>
    </div>

  <div
    v-if="route.meta.layout !== 'hide' && isShow && !isLargeViewport"
    class="fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50 overflow-hidden  rounded-t-xl"
    :style="{ height: drawerHeight > 70 ? `calc(${drawerHeight}% - 50px)` : `${drawerHeight}%` }"
    @mousemove="onDrag"
    @mouseup="endDrag"
    @touchmove="onDrag"
    @touchend="endDrag"
  >
    <div class="drawer-header p-1 shadow z-10 cursor-move"
      @mousedown="startDrag"
      @touchstart="startDrag"
    >
      <div class="flex justify-center">
        <div class="w-28 h-1.5 bg-gray-200 rounded mt-1"></div>
      </div>
      <div class="flex items-center justify-between">
        <div class="font-bold pl-1">{{ route.meta.title }}</div>
        <!-- drag line -->
        <Button
          @click="isShow = false"
          variant="ghost"
          class="w-6 h-6 p-0 rounded-full"
        >
          <X class="w-5 h-5 text-gray-600" />
        </Button>
      </div>
    </div>

    <div class="flex-1 overflow-y-auto h-full">
      <router-view />
    </div>
  </div>
</template>

<style scoped>
body {
  user-select: none;
}
</style>
