<script setup>
import {computed, onBeforeMount, watch, ref, onMounted} from 'vue'
import {useStore} from 'vuex'
import {onAuthStateChanged} from 'firebase/auth'

import {useRouter, useRoute} from 'vue-router'
import { firebase } from '@/router'
import WsHandler from '@/services/wsHandler.vue'
import { HOST, API } from '@/utils/http'
import * as Sentry from '@sentry/vue'
import axios from 'axios'

import Toaster from '@/components/ui/toast/Toaster.vue'
import {useToast} from '@/components/ui/toast/use-toast'
import { Header}  from '@/components/header'
import { Footer } from '@/components/footer'
import { MainMap, SideBySide } from '@/components/map'
import { LayoutDialog, LayoutSidebar } from "@/layouts"
import { SelectedFilters, DatePicker } from '@/components/filters'
import { Breadcrumbs } from '@/components/breadcrumbs'
import {SplitPane} from '@/components/splitpane'
import {ProjectList} from '@/components/project-list'
import { auth } from 'firebaseui'

const router = useRouter()
const route = useRoute()
const projectId = computed(() => parseInt(route.params.project_id))

const store = useStore()
const { toast } = useToast()

const member = computed(() => store.getters['user/getMember'])
const alerts = computed(() => store.getters.getAlerts)
const isDragging = ref(false)
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)

// todo: use toast() in codebase directly instead of commit('SET_ALERT')
watch(alerts.value, () => {
  if (alerts.value.length) {
    alerts.value.forEach((alert) => {
      toast({
        title: alert.text
      })
    })
  }
})

const fetchMember = async () => {
  try {
    const result = await API({
      method: 'GET',
      url: `${HOST}/member`
    })
    return result.data
  } catch (e) {
    throw new Error(e)
  }
}

const fetchTokens = async (firebaseToken) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${HOST}/token/`,
      data: {
        firebase_token: firebaseToken
      }
    })

    return response.data
  } catch (e) {
    console.log(e)
  }
}

onBeforeMount(async () => {
  onAuthStateChanged(firebase.auth, async (user) => {
    if (user) {
      await store.dispatch('user/setupSettings')
      await store.dispatch('user/setUser', user)

      try {
        const firebaseToken = await firebase.auth.currentUser.getIdToken(true)
        const tokens = await fetchTokens(firebaseToken)

        if (!tokens) {
          // Go to sign up if user have no tokens; a user doesn't have tokens
          // if they have no invites or are not an organization or project member.
          router.push({name: 'AuthSignUp'})
          return
        }

        localStorage.setItem('accessToken', tokens.access_token)
        localStorage.setItem('refreshToken', tokens.refresh_token)

        const member = await fetchMember()

        if (member) {
          store.dispatch('user/setMember', member)
          store.commit('SET_IS_LOGGEDIN', true)
          Sentry.setUser({email: member.email})

          if (route.name === 'AuthSignUp' || route.name === 'AuthLogin') {
            router.push({name: 'Dashboard'})
          }
        }

        await fetchInitialData()
      } catch (e) {
        console.log(e)
        logOut()
      }
    } else {
      logOut()
    }
  })
})

const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)

onMounted(async () => {
  await router.isReady()
  store.commit('search/SET_PARAMS', route.query)


  window.addEventListener('resize', appHeight)
  appHeight()
})

watch(
  () => member.value,
  async (value) => {
    if (value) {
      zE('webWidget', 'identify', {
        name: value.display_name ? value.display_name : value.email,
        email: value.email
      })
    }
  }
)

const fetchInitialData = async () => {
  await store.dispatch('org/fetchOrgs')
  await store.dispatch('project/fetchProjects')
  await store.dispatch('project/fetchAllMembers')
  await store.dispatch('project/fetchAllTags')
}

const logOut = () => {
  store.dispatch('logout')
}

const handleDrop = (e) => {
  e.preventDefault()
  e.stopPropagation()

  isDragging.value = false

  const file = e.dataTransfer.files[0]

  if (!file) {
    return
  }

  const ext = file.name.split('.').pop().toLowerCase()
  if (!['kml', 'kmz', 'geojson', 'jpg', 'jpeg', 'png', 'heic', 'heif', 'avif', 'webp'].includes(ext)) {
    store.commit('SET_ALERT', {type: 'error', text: "Unsupported file format"})
    return
  }

  store.commit('geo/SET_FILES', e.dataTransfer.files)

  if (route.name !== 'FileViewer') {
    router.push({name: 'FileViewer'})
  }
}
</script>

<template>
<div v-if="isLoggedIn" class="z-40 h-[50px] shadow">
  <ws-handler></ws-handler>
  <Header />
</div>
<div v-else class="h-[50px] bg-gray-50"></div>

<main class="h-full z-40 text-sm main-section">
  <SplitPane>
    <template v-slot:left>
      <div class="min-w-[300px] bg-white">
        <ProjectList />
      </div>
    </template>
    <template v-slot:right>
      <div
        class="w-full h-full main-section relative overflow-hidden bg-gray-50"
        @dragenter="isDragging = true"
      >
        <div class="w-full h-full">
          <MainMap v-if="isLoggedIn" />
          <div
            v-if="isLoggedIn && isDragging"
            @dragover.prevent
            @drop.stop.prevent="handleDrop"
            @dragleave="isDragging = false"
            class="absolute top-0 left-0 z-100 w-full h-full bg-gray-600 opacity-20"
          ></div>
          <div class="absolute top-2 bottom-2 left-2 right-0 md:right-auto max-w-[100%] pointer-events-none">
            <div v-if="isLoggedIn" class="mb-2">
              <Breadcrumbs class="pointer-events-auto" />
            </div>

            <div class="flex flex-1 items-end pointer-events-none">
              <div
                class="overflow-y-auto rounded-md bg-white shadow-lg pointer-events-auto mr-2"
                style="max-height: calc(var(--app-height) - 152px); min-height: calc(var(--app-height) - 152px);"
              >
                <LayoutDialog v-if="$route.meta.layout === 'modal'" />
                <div v-else-if="$route.meta.layout === 'hide'"></div>
                <LayoutSidebar v-else />
              </div>

              <div>
                <div class="pointer-events-none" v-if="isLoggedIn">
                  <SideBySide class="pointer-events-auto" />
                </div>
              </div>
            </div>
          </div>

          <div class="absolute top-0 right-0" v-if="isLoggedIn">
            <div class="m-2 flex items-end flex-col">
              <div class="mb-2 flex items-center space-x-2">
                <div v-if="projectId">
                  <DatePicker />
                </div>
              </div>
              <div class="mb-2" v-if="projectId">
                <SelectedFilters />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </SplitPane>
</main>

<div class="absolute bottom-0 left-0 right-0 z-40 h-[50px] shadow-lg hidden lg:block pointer-events-auto">
  <Footer />
</div>
<Toaster v-if="isLoggedIn" />
</template>

<style>
.main-section {
  max-height: calc(var(--app-height) - 50px);
  min-height: calc(var(--app-height) - 50px);

  @media (min-width: 1024px) {
    max-height: calc(var(--app-height) - 100px);
    min-height: calc(var(--app-height) - 100px);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
